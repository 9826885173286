import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VUTranslateModule } from '../vu-translate/vu-translate.module';
import { ComponentsModule } from '../components/components.module';
import { ReceiptSwitcherComponent } from '../../components/shop/receipt-switcher/receipt-switcher.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ReceiptSwitcherComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    VUTranslateModule,
    ComponentsModule,
    FormsModule,
    MatSlideToggleModule,
  ],
  exports: [
    ReceiptSwitcherComponent,
  ]
})
export class ReceiptModule { }
