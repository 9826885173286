<table>
  <colgroup>
    <col width="30%">
    <col width="70%">
  </colgroup>
  <tbody>
    <tr>
      <td colspan=2>
        <i class="fa fa-2x" [ngClass]="statusInfo.cssClass" aria-hidden="true"></i>
      </td>
    </tr>
    <tr>
      <td>{{ 'Status' | translate }}</td>
      <!-- <td>{{ statusInfo.text | translate }}</td> -->
      <td>{{ statusInfotext  }}</td>
    </tr>
    <tr *ngIf="ticket && !ticket.isUnknown && !isStaff">
      <td>{{ 'Product' | translate }}</td>
      <td>{{ productName }}</td>
    </tr>
    <tr *ngIf="ticket && !ticket.isUnknown && !isStaff  && ticket.isAdditionalTicket">
      <td>{{ 'Ticket type' | translate }}</td>
      <td>{{ 'Additional ticket' | translate }}</td>
    </tr>
    <tr>
      <td>Ticket</td>
      <td>{{ ticket.code }}</td>
    </tr>
    <tr *ngIf="viewQuantityInfo">
      <td>{{ 'Quantity Used' | translate }} / {{ 'Quantity' | translate }}</td>
      <td>{{ ticket.quantityUsed }} / {{ ticket.quantity }}</td>
    </tr>
    <tr *ngIf="ticket && !ticket.isUnknown && isRoleExit && ticket.totalOvertime > 0 && !ticket.validUntil">
      <td>{{ 'Total overtime' | translate }}</td>
      <td>{{ ticket.totalOvertime }} {{ 'minutes' | translate }}</td>
    </tr>
    <tr *ngIf="ticket && !ticket.isUnknown && isRoleExit && ticket.outstandingOvertime > 0 && !ticket.validUntil">
      <td>{{ 'Oustanding overtime' | translate }}</td>
      <td>{{ ticket.outstandingOvertime }} {{ 'minutes' | translate }}</td>
    </tr>

    <tr *ngIf="ticket && !ticket.isUnknown && ticket.validFromExist && !ticket.isAdditionalTicket">
      <td>{{ 'From Time' | translate }}</td>
      <td>{{ ticket.validFromString }}</td>
    </tr>

    <tr *ngIf="ticket && !ticket.isUnknown && ticket.validToExist && !ticket.isAdditionalTicket">
      <td>{{ 'To Time' | translate }}</td>
      <td>{{ ticket.validToString }}</td>
    </tr>

    <tr *ngIf="ticket && ticket.validUntil && isTicketValid && !ticket.isAdditionalTicket">
      <td>{{ 'Valid Until' | translate }}</td>
      <td>{{ ticket.ValidUntilString }}</td>
    </tr>
  </tbody>
</table>
