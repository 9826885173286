import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service'
import { UiService } from '../../../services/ui.service';
import { LanguageService } from '../../../services/language.service';
import { AssetsService } from '../../../services/assets.service';
import { DispatcherService } from '../../../services/dispatcher.service';
import { Configuration } from '../../../lib/lib';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification/notification.service';
import { NotificationMessage } from '../../../lib/notification-message/notification-message';
import { Router } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'page-header',
  templateUrl: 'page-header.component.html',
  styleUrls: ['page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  imgSrc: string;
  defaultLogoPath = './../../../../static/images/logo.png';
  subscription: Subscription;
  languageButtonActivate = '';
  languageButtonSubscription: Subscription;

  constructor(
    public uiService: UiService,
    public configurationService: ConfigurationService,
    private languageService: LanguageService,
    public assetsService: AssetsService,
    public dispatcherService: DispatcherService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
  }

  get showLanguagesButton(): boolean {
    return this.languageService.showLanguagesButton;
  }

  ngOnInit(): void {
    this.subscription = this.dispatcherService.onConfigurationChangedSubscribe((configuration: Configuration) => {
      this.reloadLogo(configuration.customLogoId);
    });
    this.languageButtonSubscription = this.dispatcherService.onLanguagesButtonModalActivate((message) => {
      if (message && 'info' in message && message.info === false) {
        this.languageButtonActivate = 'langDeactivated';
      } else {
        this.languageButtonActivate = '';
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.languageButtonSubscription) {
      this.languageButtonSubscription.unsubscribe();
    }
  }

  reloadLogo(logoId: number): void {
    switch (logoId) {
      case -1:
        this.imgSrc = null;
        break;
      case 0:
      case null:
        this.imgSrc = this.defaultLogoPath;
        break;
      default:
        this.imgSrc = this.assetsService.getImageUrlById(logoId);
        break;
    }
  }

  get notificationMessage(): NotificationMessage {
    if (!this.notificationService
      || !this.notificationService.notificationMessage
      || !this.notificationService.notificationMessage.message) {
      return null;
    }
    return this.notificationService.notificationMessage;
  }

  get isShowCardDetailInformationButton(): boolean {
    return !this.router.url.includes('card-detail-information')
      && this.configurationService.configuration.originalConfigurationHelper?.getBoolean('show_lost_card_management_button');
  }

}
