import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { KeyboardType } from 'src/app/components/general/on-screen-keyboard/on-screen-keyboard.component';
import { BarcodeInputMethod } from 'src/app/lib/barcode-reader/barcode-input-method';
import { BarcodeReaderService } from 'src/app/services/barcode/barcode-reader.service';
import { AdditionalPropertiesConfigurationService } from 'src/app/services/configuration/additional-properties-configuration.service';
import { ConfigurationService } from 'src/app/services/configuration/configuration.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';

@Component({
  selector: 'app-barcode-scan',
  templateUrl: './barcode-scan.component.html',
  styleUrls: ['./barcode-scan.component.css']
})
export class BarcodeScanComponent implements OnInit, OnDestroy {

  @Output()
  barcodeLedEnabledChange = new EventEmitter<boolean>();

  barcodeInputMethod = BarcodeInputMethod.VirtualKeyboard;
  isDisabled = false;

  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private additionalPropertiesConfigurationService: AdditionalPropertiesConfigurationService,
    private barcodeReaderService: BarcodeReaderService,
  ) { }

  ngOnInit(): void {
    this.barcodeInputMethod = this.configurationService.barcodeInputMethod;
    if (this.isBarcodeReaderAvailable) {
      this.barcodeLedEnable(true);
    }
  }

  ngOnDestroy(): void {
    if (this.isBarcodeReaderAvailable) {
      this.barcodeLedEnable(false);
    }
  }

  private get isBarcodeReaderAvailable(): boolean {

    switch (this.barcodeInputMethod) {
      case BarcodeInputMethod.BarcodeReader:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardCharsOnly:
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardNumbersOnly:
        return true;
    }

    return false;
  }

  private barcodeLedEnable(value: boolean): void {
    this.barcodeReaderService.barcodeLedEnable(value);
    this.barcodeLedEnabledChange.emit(value);
  }

  get keyboardType(): KeyboardType {

    switch (this.barcodeInputMethod) {
      case BarcodeInputMethod.BarcodeReader:
        return KeyboardType.None;
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboard:
      case BarcodeInputMethod.VirtualKeyboard:
        return KeyboardType.All;
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardCharsOnly:
      case BarcodeInputMethod.VirtualKeyboardCharsOnly:
        return KeyboardType.CharsOnly;
      case BarcodeInputMethod.BarcodeReaderAndVirtualKeyboardNumbersOnly:
      case BarcodeInputMethod.VirtualKeyboardNumbersOnly:
        return KeyboardType.NumbersOnly;
    }

    return KeyboardType.All;
  }

  get ticketLength(): number {
    return this.additionalPropertiesConfigurationService.ticketLength;
  }

  get separatorPosition(): number {
    return this.additionalPropertiesConfigurationService.separatorPosition;
  }

  onEnteredText(text: string): void {
    this.barcodeReaderService.onBarcodeRead(text);
    this.isDisabled = true;
  }

  onTextChanged(): void {
    this.dispatcherService.onUserActivity();
  }

  get isVirtualKeyboardVisible(): boolean {
    return this.barcodeInputMethod !== BarcodeInputMethod.BarcodeReader;
  }
}
