import { Component, Input } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { LanguageService } from '../../../services/language.service';
import { Ticket, TicketStatus } from '../../../lib/lib';
import { StatusInfo } from '../../../lib/status-info';

@Component({
  selector: 'ticket-access-status',
  templateUrl: './ticket-access-status.component.html',
  styleUrls: ['./ticket-access-status.component.css'],
})
export class TicketAccessStatusComponent {

  @Input()
  ticket: Ticket;

  constructor(
    private configurationService: ConfigurationService,
    private languageService: LanguageService,
  ) {
  }

  get statusInfo(): StatusInfo {
    const status = this.ticket == null ? TicketStatus.Unknown : this.ticket.status;
    return StatusInfo.getStyleByTicketAccessStatus(status);
  }

  get isRoleExit(): boolean {
    return this.configurationService.isRoleExit;
  }

  get isStaff() {
    return this.statusInfo.status === TicketStatus.Staff;
  }

  get language(): string {
    return this.languageService.odooLanguageName;
  }

  get productName(): string {
    return this.ticket == null ? '' : this.ticket.productFormattedName(this.language);
  }

  get viewQuantityInfo(): boolean {
    return this.ticket && !this.ticket.isUnknown && !this.isStaff && this.ticket.quantity !== 0;
  }

  get isTicketValid() {
    return this.ticket && this.ticket.status === TicketStatus.Valid;
  }
}
