<div class="payment-card normal-text">
  <div class="block">
    <div class="payment-card-info">{{ 'Amount to pay' | translate }}</div>
    <div class="payment-card-info amount">{{ amountToPay }}</div>
  </div>
  <div class="messages" *ngIf="cashlessPaymentMessageStore">
    <div [ngClass]="cssClassMessage">{{ cashlessPaymentMessageStore.message | translate }}</div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <receipt-switcher *ngIf="showReceiptButton" [receiptType]='printReceiptTypeEnums.OrderReceipt' [cssClass]="receiptSwitcherStyle"></receipt-switcher>
      </div>
    </div>
  </div>
</div>
