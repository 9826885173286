export const DEUTSCHE_LANGUAGE_ID = 'de-DE';

export const ENGLISH_LANGUAGE_ID = 'en-US';

export const CZECH_LANGUAGE_ID = 'cs-CZ';

export const UKRAINIAN_LANGUAGE_ID = 'uk-UA';

export const POLISH_LANGUAGE_ID = 'pl-PL';

export const FRENCH_LANGUAGE_ID = 'fr-FR';

export const ROMANIAN_LANGUAGE_ID = 'ro-RO';

export const TURKISH_LANGUAGE_ID = 'tr-TR';

export const SPANISH_LANGUAGE_ID = 'es-ES';

export const RUSSIAN_LANGUAGE_ID = 'ru-RU';

export const ITALIAN_LANGUAGE_ID = 'it-IT';

export const BULGARIAN_LANGUAGE_ID = 'bg-BG';

export const SERBIAN_LANGUAGE_ID = 'sr-RS';

export const CROATIAN_LANGUAGE_ID = 'hr-HR';

export const HUNGARIAN_LANGUAGE_ID = 'hu-HU';

export const LITHUANIAN_LANGUAGE_ID = 'lt-LT';

export const LATVIAN_LANGUAGE_ID = 'lv-LV';

export const DEFAULT_LANGUAGE_ID = DEUTSCHE_LANGUAGE_ID;

export class Locale {
  id: string;
  displayName: string;

  static convertToOdooLanguageId(languageAngularId: string): string {
    if (languageAngularId) {
      return languageAngularId.replace('-', '_');
    }
    return '';
  }
}

export const LOCALES: Locale[] = [
  { id: ENGLISH_LANGUAGE_ID, displayName: 'English' },
  { id: DEUTSCHE_LANGUAGE_ID, displayName: 'Deutsch' },
  { id: UKRAINIAN_LANGUAGE_ID, displayName: 'Українська' },
  { id: CZECH_LANGUAGE_ID, displayName: 'Čeština' },
  { id: POLISH_LANGUAGE_ID, displayName: 'Polski' },
  { id: FRENCH_LANGUAGE_ID, displayName: 'Français' },
  { id: ROMANIAN_LANGUAGE_ID, displayName: 'Română' },
  { id: TURKISH_LANGUAGE_ID, displayName: 'Türkçe' },
  { id: SPANISH_LANGUAGE_ID, displayName: 'Español' },
  { id: RUSSIAN_LANGUAGE_ID, displayName: 'Русский' },
  { id: ITALIAN_LANGUAGE_ID, displayName: 'Italiano' },
  { id: BULGARIAN_LANGUAGE_ID, displayName: 'Български' },
  { id: SERBIAN_LANGUAGE_ID, displayName: 'Српски' },
  { id: CROATIAN_LANGUAGE_ID, displayName: 'Hrvatski' },
  { id: HUNGARIAN_LANGUAGE_ID, displayName: 'Magyar' },
  { id: LITHUANIAN_LANGUAGE_ID, displayName: 'Lietuvių' },
  { id: LATVIAN_LANGUAGE_ID, displayName: 'Latviešu' },
];
