import { MachineBaseService } from './machine-base.service';
import {
  PaymentSession,
  MachineInactivitySettings,
  PaymentMethod
} from '../../lib/lib';
import { Message, MessageType } from '../message.service';
import { Subject, Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { CashlessPaymentProviderEvent } from 'src/app/lib/payment/cashless-payment-provider-event';
import { CashlessPaymentProviderEventType } from 'src/app/lib/payment/cashless-payment-provider-event-type';
import { CashlessTransactionResult } from 'src/app/lib/payment/cashless-payment-transaction-result';
import { ICashlessPaymentProvider } from 'src/app/lib/payment/cashless-payment-provider.interface';
import { CashlessCardProviderService } from '../payment/cashless-providers/cashless-card-provider.service';
import { SaleService } from '../sale.service';
import { PaymentMessageStore } from 'src/app/lib/payment/payment-message-store';
import { CashlessBluecodeProviderService } from '../payment/cashless-providers/cashless-bluecode-provider.service';
import { CashlessGiftCardProviderService } from '../payment/cashless-providers/cashless-giftcard-provider.service';
import { CashlessPaymentData } from 'src/app/lib/payment/cashless-payment-data';

@Injectable()
export class MachineSaleCashlessService extends MachineBaseService {

  evenCashlessTransactionEnd = new Subject<CashlessTransactionResult>();
  disableNavigation = false;
  readonly DELAY_FOR_REMOVE_CARD = 5000;

  private saleService: SaleService;
  private _cashlessMessageStore: PaymentMessageStore;
  private _paymentData: CashlessPaymentData;

  private _paymentMethod: PaymentMethod;
  private _cashlessPaymentProviders: Map<PaymentMethod, ICashlessPaymentProvider>;
  private _cashlessPaymentProviderSubscription: Subscription;

  init(): void {
    this.saleService = this.injector.get(SaleService);
    this._cashlessMessageStore = this.saleService.cashlessPaymentMessageStore;
    if (!this._cashlessMessageStore) {
      this.log.error(
        'this.saleService.creditCardPaymentMessage must be initialized!'
      );
    }

    this._cashlessPaymentProviders = new Map<PaymentMethod, ICashlessPaymentProvider>();

    const cashlessPaymentProvider = this.injector.get(CashlessCardProviderService);
    this._cashlessPaymentProviders.set(PaymentMethod.PaymentCard, cashlessPaymentProvider);

    // TODO MODULE INSTANCE FROM SHARED ?
    const bluecodePaymentProvider = this.injector.get(CashlessBluecodeProviderService);
    this._cashlessPaymentProviders.set(PaymentMethod.Bluecode, bluecodePaymentProvider);

    const giftCardPaymentProvider = this.injector.get(CashlessGiftCardProviderService);
    this._cashlessPaymentProviders.set(PaymentMethod.GiftCard, giftCardPaymentProvider);

    super.init();
  }

  get cashlessPaymentProvider(): ICashlessPaymentProvider {
    return this._cashlessPaymentProviders?.get(this.paymentMethod);
  }

  get paymentMethod(): PaymentMethod {
    return this._paymentMethod;
  }

  set paymentMethod(value: PaymentMethod) {
    if (this._paymentMethod !== value) {
      this.log.info(`Changed payment method. From "${this._paymentMethod || 'none'}" to "${value || 'none'}"`);
      this._paymentMethod = value;
    }
  }

  set paymentData(value: CashlessPaymentData) {
    if (value === this._paymentData) {
      return;
    }

    this._paymentData = value;

    if (value) {
      this.paymentMethod = value.paymentMethod;
    } else {
      this.paymentMethod = null;
    }
  }

  unsubscribeProviderEvents(): void {
    if (this._cashlessPaymentProviderSubscription) {
      this._cashlessPaymentProviderSubscription.unsubscribe();
      this._cashlessPaymentProviderSubscription = null;
    }
  }

  resubscribeProviderEvents(): void {
    this.unsubscribeProviderEvents();
    const cashlessPaymentProvider = this.cashlessPaymentProvider;
    if (!cashlessPaymentProvider) {
      return;
    }

    cashlessPaymentProvider.paymentData = this._paymentData?.data;
    this._cashlessPaymentProviderSubscription = cashlessPaymentProvider.eventCashlessPaymentProviderEvent
      .subscribe((x: CashlessPaymentProviderEvent) => this.onEventCashlessPaymentProvider(x));
  }

  get machineName(): string {
    return this.cashlessPaymentProvider?.name || MachineSaleCashlessService.name;
  }

  protected getTransitions(): any[] {
    return super.getTransitions(
      { name: 'toTransactionBegin', from: ['*'], to: 'transactionBegin' },
      { name: 'toCanceled', from: ['transactionBegin', 'paymentInProgress'], to: 'canceled' },
      { name: 'toPaymentInProgress', from: ['*'], to: 'paymentInProgess' },
      { name: 'toPaymentCompleted', from: ['*'], to: 'paymentCompleted' },
      { name: 'toTransactionEnd', from: ['transactionBegin', 'canceled', 'paymentInProgress', 'paymentCompleted'], to: 'transactionEnd' },
      { name: 'toOff', from: ['*'], to: 'off' }
    );
  }

  protected getMethods(): any {
    const scope = this;
    return super.getMethods({
      onToOff(event: any, isHardReset: false): void {
        scope.disableNavigation = false;
        if (scope.cashlessPaymentProvider) {
          scope.cashlessPaymentProvider.transactionEnd();
        }
        scope.paymentMethod = null;
      },
      onToTransactionBegin(): void {
        scope.resubscribeProviderEvents();
        if (scope.cashlessPaymentProvider) {
          scope.cashlessPaymentProvider.init();
        }

        scope.sessionOpenPaymentSessionCachless().then(() => {
          if (scope.cashlessPaymentProvider) {
            scope.cashlessPaymentProvider.transactionBegin();
          }
          if (!scope.disableNavigation) {
            scope.router.navigateByUrl('/payment-cashless');
          }
        });
      },
      onToPaymentInProgress(): void {
      },
      onToCanceled(): void {
        scope.vuHttp.abortCashlessTransaction(scope.saleService.order.paymentMethod);
        scope.doAsync(() => scope.machine.toTransactionEnd(CashlessTransactionResult.Canceled));
      },
      onToPaymentCompleted(): void {
      },
      onToPaymentAborted(): void {
      },
      onToTransactionEnd(t: any, transactionResult: CashlessTransactionResult): void {
        const callback = () => {
          scope.evenCashlessTransactionEnd.next(transactionResult);
          scope.unsubscribeProviderEvents();

          if (scope.cashlessPaymentProvider) {
            scope.cashlessPaymentProvider.transactionEnd();
          }
        }
        if (transactionResult === CashlessTransactionResult.Completed) {
          scope.saleService.paymentSession.registerCompleteAmountToPay();
          const timeout = scope._isLiteMode ? scope.DELAY_FOR_REMOVE_CARD : 0;
          setTimeout(() => callback(), timeout);
        } else {
          scope.saleService.remoteTransaction.closeRemoteTransaction(false, 'onToPaymentCanceled', callback);
        }

      }
    });
  }

  protected getMessages(): MessageType[] {
    return super.getMessages(
      MessageType.ButtonBackClicked,
    );
  }

  protected onMessage(message: Message): boolean {
    if (super.onMessage(message)) {
      return true;
    }

    switch (message.messageType) {
      case MessageType.ButtonBackClicked:
        this.machine.toCanceled();
        break;
      default:
        break;
    }
    return false;
  }

  private setMessage(x: string, highlight = false): void {
    this._cashlessMessageStore.message = x;
    this._cashlessMessageStore.higlightMessage = highlight;
  }

  machineStart(): void {
    this.machine.toTransactionBegin();
  }

  get cardTerminalPaymentInactivityTimeoutMs(): number {
    return this.configurationService?.configuration?.originalConfigurationHelper?.getNumber('card_terminal_payment_inactivity_timeout_ms') || 0;
  }

  protected get timeoutTrackingStates(): string[] {

    if (this.disableNavigation || (this.paymentMethod === PaymentMethod.PaymentCard && this.cardTerminalPaymentInactivityTimeoutMs == 0)) {
      return [];
    }
    return this.getsAllStatesExceptFor('off');
  }

  protected getMachineInactivitySettings(
    state: string
  ): MachineInactivitySettings {

    const cardTerminalPaymentInactivityTimeoutMs = this.cardTerminalPaymentInactivityTimeoutMs;
    if (this.paymentMethod === PaymentMethod.PaymentCard && cardTerminalPaymentInactivityTimeoutMs != 0) {
      return new MachineInactivitySettings(cardTerminalPaymentInactivityTimeoutMs);
    }

    return new MachineInactivitySettings(180000);
  }

  protected onMachineTimeoutModalCancel(machineName: string): void {
    this.machine.toCanceled();
    if (!this._isLiteMode) {
      super.onMachineTimeoutModalCancel(machineName);
    }
  }

  onTextChanged(terminalText: string): void {
    if (terminalText && terminalText.length > 0) {
      this.setMessage(terminalText, true);
    }
  }

  private onEventCashlessPaymentProvider(x: CashlessPaymentProviderEvent): void {

    // block events from another provider

    this.log.info(`onEventCashlessPaymentProviderEvent. ${x}`);
    if (this.state === 'off') {
      this.log.info(`onEventCashlessPaymentProviderEvent. The machine is off. Ignore the event.`);
      return;
    }

    switch (x.eventType) {
      case CashlessPaymentProviderEventType.PaymentInProgress:
        this.machine.toPaymentInProgress();
        break;
      case CashlessPaymentProviderEventType.TextChanged:
        this.onTextChanged(x.eventInfo);
        break;
      case CashlessPaymentProviderEventType.PaymentCompleted:
        this.machine.toTransactionEnd(CashlessTransactionResult.Completed);
        break;
      case CashlessPaymentProviderEventType.PaymentAborted:
        this.machine.toCanceled();
        break;
      default:
        this.log.error(`This PaymentProviderEvent not supported: ${x}`);
        break;
    }
  }

  switchOff(callback: any): void {
    const scope = this;
    this.doAsync(() => {
      scope.machine.toOff();
      callback();
    });
  }

  private sessionOpenPaymentSessionCachless(): Promise<void> {
    return this.saleService.openPaymentSession(this._paymentMethod, this.saleService.order.amountTotal);
  }

  private get _isLiteMode(): boolean {
    return this.additionalPropertiesConfigurationService?.isLiteMode;
  }
}
