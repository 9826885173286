import { Component } from '@angular/core';
import { MachineSaleShopService } from '../../../services/machines/machine-sale.service';
import { ConfigurationService } from '../../../services/configuration/configuration.service';
import { StoreHistoryService } from 'src/app/services/store-history.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { CustomShopInformation } from 'src/app/lib/custom-shop/custom-shop.information';

@Component({
  selector: 'app-restore-card',
  templateUrl: './restore-card.component.html',
  styleUrls: ['./restore-card.component.css'],
})
export class RestoreCardButtonComponent {
  constructor(
    private dispatcherService: DispatcherService,
    private configurationService: ConfigurationService,
    private machineSaleShopService: MachineSaleShopService,
    private storeHistoryService: StoreHistoryService,
  ) {
  }

  get visible(): boolean {
    const state = this.machineSaleShopService.state;
    return state && state === 'idle'
      && this.storeHistoryService.isEmpty
      && this.configurationService.configuration.lostCardConfiguration != null;
  }

  onClick(): void {
    this.dispatcherService.onButtonCustomSaleShopClick(
      new CustomShopInformation('/card-dispenser-restore-card', '/card-dispenser-restore-card/restore'));
  }
}
