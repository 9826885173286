<label [attr.for]="'ticketPrinterType'">{{ ('Model' | translate) + ':' }}</label>
<select id="ticketPrinterType" [(ngModel)]="configuration.ticketPrinterType" (change)="onTicketPrinterTypeChange()">
  <option *ngFor="let r of printerTypes" [ngValue]="r">{{ r | translate }}</option>
</select>

<a type="button" class="btn btn-default" [ngClass]="{disabled : !shopState.isTicketPrinting}" (click)="onTicketPrintedClick(true)">{{
  'Ticket Print OK' | translate }}</a>
<a type="button" class="btn btn-default" [ngClass]="{disabled : !shopState.isTicketPrinting}" (click)="onTicketPrintedClick(false)">{{
  'Ticket Print Not OK' | translate }}</a>
<a type="button" class="btn btn-default" [ngClass]="{disabled : !ticketRemovedButtonEnabled}" (click)="onTicketRemovedClick()">{{
  'Ticket Removed' | translate }}</a>

<label [attr.for]="'receiptPrinterType'">{{ ('Model' | translate) + ':' }}</label>
<select id="receiptPrinterType" [(ngModel)]="configuration.receiptPrinterType" (change)="onReciptPrinterTypeChange()">
  <option *ngFor="let r of printerTypes" [ngValue]="r">{{ r | translate }}</option>
</select>

<a type="button" class="btn btn-default" [ngClass]="{disabled : !shopState.isReceiptPrinting}" (click)="onReceiptPrintedClick(true)">{{
  'Receipt Print OK' | translate }}</a>
<a type="button" class="btn btn-default" [ngClass]="{disabled : !shopState.isReceiptPrinting}" (click)="onReceiptPrintedClick(false)">{{
  'Receipt Print Not OK' | translate }}</a>
<a type="button" class="btn btn-default" [ngClass]="{disabled : !receiptRemovedButtonEnabled}" (click)="onReceiptRemovedClick()">{{
  'Receipt Removed' | translate }}</a>
<a type="button" class="btn btn-default" (click)="onCreditCardReceiptPrintedClick(true)">{{
  'CC Receipt OK'| translate }}</a>
<a type="button" class="btn btn-default" (click)="onCreditCardReceiptPrintedClick(false)">{{
  'CC Receipt NOK'| translate }}</a>

<label [attr.for]="'receiptCardTerminalPrinterEnum'">{{ ('Print card terminal receipt' | translate) + ':' }}</label>
<select id="receiptCardTerminalPrinterEnum" [(ngModel)]="configuration.printCardTerminalReceipt" (change)="onPrintCardTerminalReceiptChange()">
  <option *ngFor="let r of receiptPrinterEnum" [ngValue]="r">{{ r | translate }}</option>
</select>

<label [attr.for]="'receiptCashPrinterEnum'">{{ ('Print cash receipt' | translate) + ':' }}</label>
<select id="receiptCashPrinterEnum" [(ngModel)]="configuration.printOrderReceipt" (change)="onPrintOrderReceiptChange()">
  <option *ngFor="let r of receiptPrinterEnum" [ngValue]="r">{{ r | translate }}</option>
</select>
